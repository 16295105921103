<template>
    <!-- 供应商 -->
    <div class="digital-customer">
        <a-card>
            <template #title>
                <a-space>
                    <a-button type="primary" @click="showSupplier(null)" v-has="{action:'wxapp_storage_supplier_add',plat:0}">
                        <i class="ri-add-line ri-btn"></i>新增供应商
                    </a-button>
                    <com-upload-btn
                        v-has="{action:'wxapp_storage_supplier_import',plat:0}"
                        btn-text="批量导入"
                        btn-type="default"
                        :customUpload="true"
                        @uploadChange="importSupplier" >
                    </com-upload-btn>
                </a-space>
            </template>
            <template #extra>
                <a-input-search
                    v-model:value="slState.search.key"
                    placeholder="输入客户编号/名称/联系人/电话/备注查询"
                    enter-button
                    style="width:400px"
                    @search="getSupplier(1,slState.limit)"
                />
            </template>
            <a-table :pagination="false" rowKey="id" :data-source="slState.list" :columns="[
                {title:'供应商编号',dataIndex:'id'},
                {title:'供应商名称',dataIndex:'nickname'},
                {title:'联系人',dataIndex:'contact_person'},
                {title:'手机',dataIndex:'mobile'},
                {title:'QQ/微信/email',dataIndex:'contact_link'},
                {title:'应收款余额',dataIndex:'receivables'},
                {title:'联系地址',dataIndex:'address'},
                {title:'备注',dataIndex:'remark'},
                {title:'状态',dataIndex:'status',slots:{customRender: 'status'}},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" :row-selection="{ selectedRowKeys: slState.pks, onChange:(e)=>{slState.pks = e}  }">
                <template #status="{record}">
                    <a-tooltip :title="record.status === 1 ?'切换状态为禁用':'切换状态为启用'">
                        <a-tag class="pt" :color="record.status ?'#00CC66':'#FF0066'"
                               @click="changeSupplier([record.id],record.status === 1 ?0:1)">
                            {{record.status === 1 ?'启用':'禁用'}}
                        </a-tag>
                    </a-tooltip>
                </template>
                <template #action="{record}">
                    <kd-button title="编辑" icon="ri-edit-line" @click="showSupplier(record)"
                               v-has="{action:'wxapp_storage_supplier_add',plat:0}"></kd-button>
                    <kd-button title="删除"
                               icon="ri-delete-bin-2-line"
                               type="danger"
                               @click="deleteSupplier([record.id])"
                               v-has="{action:'wxapp_storage_supplier_delete',plat:0}">
                    </kd-button>
                </template>
            </a-table>
            <kd-pager :page-data="slState" :event="getSupplier">
                <a-space>
                    <a-button @click="changeSupplier(slState.pks,0)"
                              v-has="{action:'wxapp_storage_supplier_status',plat:0}">禁用
                    </a-button>
                    <a-button @click="changeSupplier(slState.pks,1)" v-has="{action:'wxapp_storage_supplier_status',plat:0}">
                        启用
                    </a-button>
                    <a-tooltip title="未勾选要导出的数据时，导出的为导入模板" v-has="{action:'wxapp_storage_supplier_export',plat:0}">
                        <a-button @click="exportSupplier">导出（数据/模板）</a-button>
                    </a-tooltip>
                    <a-button @click="deleteSupplier(slState.pks)" v-has="{action:'wxapp_storage_supplier_delete',plat:0}">
                        删除
                    </a-button>
                </a-space>
            </kd-pager>
        </a-card>
        <a-modal v-model:visible="esState.show" title="添加供应商" @ok="saveSupplier" width="1000px">
            <a-form :label-col="{span:6}" :wrapper-col="{span:18}" v-if="esState.form">
                <a-row :gutter="[16,16]">
                    <a-col :span="12">
                        <a-form-item label="供应商名称" required>
                            <a-input v-model:value="esState.form.nickname"></a-input>
                        </a-form-item>
                        <a-form-item label="联系人" required>
                            <a-input v-model:value="esState.form.contact_person"></a-input>
                        </a-form-item>
                        <a-form-item label="电话号码">
                            <a-input v-model:value="esState.form.mobile"></a-input>
                        </a-form-item>
                        <a-form-item label="地址">
                            <a-input v-model:value="esState.form.address"></a-input>
                        </a-form-item>
                        <a-form-item label="QQ/微信/邮箱">
                            <a-input v-model:value="esState.form.contact_link"></a-input>
                        </a-form-item>
                        <a-form-item label="状态">
                            <a-radio-group v-model:value="esState.form.status">
                                <a-radio :value="1">启用</a-radio>
                                <a-radio :value="0">禁用</a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item label="备注">
                            <a-textarea v-model:value="esState.form.remark" placeholder="备注" :rows="4" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="余额日期">
                            <a-date-picker
                                v-model:value="esState.form.balance_data"
                                show-time
                                type="date"
                                placeholder="选择日期"
                                style="width: 100%"
                            />
                        </a-form-item>
                        <a-form-item label="开户银行">
                            <a-input v-model:value="esState.form.bank"></a-input>
                        </a-form-item>
                        <a-form-item label="银行账号">
                            <a-input v-model:value="esState.form.bank_account"></a-input>
                        </a-form-item>
                        <a-form-item label="纳税人识别号">
                            <a-input v-model:value="esState.form.tin"></a-input>
                        </a-form-item>
                        <a-form-item label="增值税税率">
                            <a-input type="number" v-model:value="esState.form.vat" addon-after="%"></a-input>
                        </a-form-item>
                        <a-form-item label="期初预付款">
                            <a-input v-model:value="esState.form.prepaid_fee"></a-input>
                        </a-form-item>
                        <a-form-item label="期初预收款">
                            <a-input v-model:value="esState.form.receivables"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>

			</a-form>
        </a-modal>
    </div>

</template>
<script>
import {useEditSupplier, useSupplier} from '@/models/addons/storage'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
export default {
    components:{
        comUploadBtn
    },
    setup() {
        let { slState ,getSupplier,deleteSupplier,changeSupplier,exportSupplier,importSupplier } = useSupplier()
        getSupplier(1,slState.limit)

        let { esState ,showSupplier,saveSupplier } = useEditSupplier(slState,getSupplier )

        return{
            slState ,getSupplier,deleteSupplier,changeSupplier,exportSupplier,importSupplier,
            esState ,showSupplier,saveSupplier
        }
    },
}
</script>
<style scoped lang="scss">
.digital-customer{
    .pager{
        display: flex;
        justify-content: space-between;
    }
    .dc-form{
        display: flex;
        flex-wrap: nowrap;
    }
}
</style>
